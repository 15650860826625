<template>
  <div class="row journal-line border-rad-8">
    <div class="col-12 d-flex product-details-border position-relative pe-0">
      <div class="row w-100 pe-lg-0 me-1 py-2">
        <div class="col-lg-3 col-12 mt-1">
          <label class="align-middle">Product</label>
          <AsyncSelect
            placeholder="Select Product"
            v-model="product"
            :api-service="fetchProductList"
            :additional-query="{ excluded_type: 'services', location_id: warehouseId }"
            :format-label="option => option.text"
            label="text"
            ref="productField"
          />
        </div>
        <div class="col-lg-3 col-6 mt-1">
          <label class="align-middle">Stock in hand</label>
          <input
            :value="item.stock_in_hand"
            readonly
            class="form-control text-end"
            placeholder="Stock In Hand"
          >
        </div>
        <div class="col-lg-2 col-6 mt-1">
          <label class="align-middle">Consumption Quantity</label>
          <input
              v-model="item.quantity"
              type="number"
              class="form-control text-end"
              placeholder="Consumption Qty"
              @input="handleTotalCalculation"
          >
        </div>
        <div class="col-lg-2 col-6 mt-1">
          <label class="align-middle">Consumption Rate</label>
          <input
              v-model="item.rate"
              type="number"
              class="form-control text-end"
              placeholder="Consumption Rate"
          >
        </div>
        <div class="col-lg-2 col-6 mt-1">
          <label class="align-middle">Consumption Amount</label>
          <input
              :value="item.amount"
              type="number"
              readonly
              class="form-control text-end"
              placeholder="Consumption Amount"
          >
        </div>
        <div class="col-12 mt-1">
          <label class="align-middle">Description</label>
          <textarea
              v-model="item.note"
              placeholder="Description"
              class="form-control"
              rows="1"
          />
        </div>
      </div>
      <div
          @click="$emit('onClose', index)"
          class="d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-x cursor-pointer font-medium-3">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup>
import handleStock from "@/services/modules/stock";
import handlePurchaseAndSales from "@/services/modules/purchase";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {computed, inject, ref, watch} from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";

const {fetchProductStock} = handleStock()
const {fetchProductList} = handlePurchaseAndSales();
const $route = useRoute();
const $store = useStore();
const showError = inject('showError')
const productField = ref(null);
const product = ref(null);
const $props = defineProps({
  item: {
    type: Object,
    default: {}
  },
  warehouseId: {
    type: Number,
    default: null
  },
  index: {
    type: Number
  }
})

//computed
const selectedProductId = computed(() => product.value ? product.value.id : null)
const selectedWarehouseId = computed(() => $props.warehouseId)

//watchers

watch(selectedWarehouseId, (oldValue) => {
  if(oldValue !== null) {
    product.value = null;
    productField.value.clear();
    $props.item.product_id = null;
    $props.item.stock_in_hand = 0
    $props.item.average_price = 0
  }
})

watch(selectedProductId, async (newValue) => {
  if(!newValue) {
    return;
  }

  if (!$props.warehouseId) {
    showError('Please select warehouse first');
    return;
  }

  $store.state.loading = true;
  let query = `?company_id=${$route.params.companyId}&product_id=${newValue}&warehouse_id=${$props.warehouseId}`;
  $props.item.product_id = newValue;

  await fetchProductStock(query).then(res => {
    if (res.data) {
      $props.item.stock_in_hand = res.data.product_stock;
      $props.item.rate = res.data.average_price;
      return;
    }

    $props.item.stock_in_hand = 0;
    $props.item.rate = 0;
  })

  $store.state.loading = false;
})

const handleTotalCalculation = () => {

  if ($props.item.quantity < 0) {
    showError("Quantity cannot be less than 0")
    $props.item.quantity = 0;
    return;
  }

  if($props.item.quantity === 0) {
    $props.item.amount = 0;
    return;
  }

  if ($props.item.quantity > $props.item.stock_in_hand) {
    showError("Quantity cannot be more than stock in hand")
    $props.item.quantity = $props.item.stock_in_hand;
    $props.item.amount = !!$props.item.rate ? ($props.item.rate * $props.item.stock_in_hand) : 0;
    return;
  }

  if (!!$props.item.rate) {
    $props.item.amount = !!$props.item.rate ? ($props.item.rate * $props.item.quantity).toFixed(4) : 0;
  }

}

</script>
<style scoped>
.journal-line {
  border: 1px solid #f0f2f5;
}

.journal-line:hover {
  background: #f0f2f5;
}
</style>
