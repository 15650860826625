<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          title="Add Stock Consumption"
          @onClickAddNewButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Warehouse</label>
            <v-select
              placeholder="Select Warehouse"
              v-model="formData.warehouse_id"
              :options="warehouses"
              label="text"
              :reduce="text => text.id"
            />
          </div>
          <div class="col-sm-4">
            <label for="colFormLabel">Select Project</label>
            <v-select
              placeholder="Select Project"
              :options="projects"
              label="name"
              :reduce="name => name.id"
              v-model="formData.project_id"
            />
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Date</label>
            <input type="date" class="form-control" v-model="formData.date">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-4">
            <label class="align-middle">Consumption Account</label>
            <v-select
              placeholder="Select Account Head"
              v-model="formData.account_head_id"
              :options="accountHeads"
              label="name"
              :reduce="name => name.id"
            />
          </div>
          <div class="col-md-4">
            <label class="form-label">Cost Centre</label>
            <v-select
              placeholder="Select Cost Centre"
              v-model="formData.cost_centre_id"
              :options="costCentres"
              label="name"
              :reduce="name => name.id"
            />
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Consumption No</label>
            <input type="text" class="form-control" v-model="formData.consumption_number">
          </div>
        </div>
      </div>
    </div>

    <div class="px-2 mt-2">
      <AddItemDetails
          class="mb-2"
          v-for="(item, index) in formData.item_details"
          :key="index"
          :index="index"
          :item="item"
          :warehouse-id="formData.warehouse_id"
          @onClose="onClose"
      />
    </div>
    <div class="row me-1 mt-3 px-2">
      <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
      </div>
    </div>

    <div class="p-1">
      <div class="row mt-3 px-2">
        <div class="col-12">
          <div class="mb-2">
            <label for="note" class="form-label fw-bold">Memo</label>
            <textarea v-model="formData.note" placeholder="Memo" class="form-control" rows="2"
                      id="description"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader />
  </div>
</template>
<script setup>
import {inject, onMounted, ref} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import AddItemDetails from "@/components/molecule/procurement/inventory/stock/consumption/AddItemDetails";
import AddButton from "@/components/atom/AddButton";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import useDate from "@/services/utils/day";
import handleJournal from "@/services/modules/journal";
import handleStock from "@/services/modules/stock";
import handleBusinessesLocations from "@/services/modules/businessesLocations";
import handlePurchaseAndSales from "@/services/modules/purchase";
import handleProjects from "@/services/modules/procurement/project";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import {useStore} from "vuex";

const router = useRouter()
const route = useRoute()
const date = useDate();
const store = useStore();
const showError = inject('showError');
const showSuccess = inject('showSuccess')

const {fetchStockConsumptionNumber, storeStockConsumption} = handleStock()
const {fetchBusinessLocationsList} = handleBusinessesLocations();
const {fetchAccountHeads} = handleJournal();
const {fetchProjects} = handleProjects();
const { fetchCostCentreList } = handleCostCentres()

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let warehouses = ref([])
let accountHeads = ref([])
let projects = ref([])
let costCentres = ref([])
let formData = ref({
  warehouse_id: null,
  date: null,
  business: null,
  consumption_number: null,
  note: null,
  sub_total: 0.00,
  vat: 0.00,
  total: 0.00,
  item_details: [
    {
      product_id: null,
      consumption_qty: null,
      consumption_rate: null,
      consumption_amount: null,
      vat_type: null,
      vat_amount: null,
      total_amount: null,
      note: null
    }
  ],
})

function resetForm() {
  formData.value = {
    warehouse_id: null,
    date: null,
    business: null,
    consumption_number: null,
    note: null,
    sub_total: 0.00,
    vat: 0.00,
    total: 0.00,
    item_details: [
      {
        product_id: null,
        consumption_qty: null,
        consumption_rate: null,
        consumption_amount: null,
        vat_type: null,
        vat_amount: null,
        total_amount: null,
        note: null
      }
    ],
  };
}

function navigateToListPage() {
  router.push({name: `stock-consumption-list`, params: route.params, query: route.query});
}

function handleSubmit(redirect) {
  store.state.loading = true;
  let query = `?company_id=${route.params.companyId}`;
  if(redirect) {
    saveButtonLoader.value = true
  } else {
    saveNewButtonLoader.value = true
  }
  if(!formData.value.date) formData.value.date = date.currentDate();

  let copyFormData = Object.assign({}, formData.value)
  copyFormData.item_details = JSON.stringify(formData.value.item_details)

  storeStockConsumption(query, copyFormData)
    .then(res => {
      saveButtonLoader.value = false
      saveNewButtonLoader.value = false

      if(! res.status) {
        return showError(res.message);
      }

      showSuccess(res.message);

      if(redirect) {
        navigateToListPage()
      }
    })
    .catch(err=> {
      saveButtonLoader.value = false;
      saveNewButtonLoader.value = false;
      store.state.loading = false;
      showError(err)
    })

  store.state.loading = false;
}

function onClose(index) {
  formData.value.item_details.splice(index, 1)
}

function onClickAdd() {
  formData.value.item_details.push({
    product_id: null,
    stock_in_hand: null,
    quantity: null,
    rate: null,
    amount: null,
    note: null,
  });
}

onMounted(() => {
  store.state.loading = true;
  let query = `?company_id=${route.params.companyId}`;
  const projectQuery = `${query}&time_and_interval=1`
  formData.value.date = date.currentDate();

  Promise.all([
    fetchBusinessLocationsList(query).then(res => {
      if (res.data) {
        warehouses.value = res.data;
        if (res.data && res.data.length > 0) {
          formData.value.warehouse_id = res.data[0].id;
        }
      }
    }),
    fetchStockConsumptionNumber(query).then(res => {
      if (res.data) {
        formData.value.consumption_number = res.data;
      }
    }),
    fetchAccountHeads(route.params.companyId).then(res => {
      if (res.data) {
        accountHeads.value = res.data;
      }
    }),
    fetchProjects(projectQuery).then(res => {
      if (res.status) {
        projects.value = res.data;
      }
    }),
    fetchCostCentreList(query).then(res=> {
      if(res.data) costCentres.value = res.data
    }),
  ]);
  store.state.loading = false;
})

</script>